<template>
  <div v-if="data" class="manage-request-edit">
      <component :is="state" :docId="docId" :data='data'/>
  </div>
  <div class="no-data" v-else>
      <h1>ביצעת רענון לדף לכן עליך לחזור למסך ניהול</h1>
      <Button @click="go_back" icon="pi pi-arrow-right" class="p-button-raised p-button-rounded p-button-success" />
  </div>
</template>

<script>
import { defineAsyncComponent, onMounted, ref } from '@vue/runtime-core'
import { onBeforeRouteLeave } from 'vue-router'
import router from '../../../../../router'

export default {
    props:['data','docId'],
    components:{
        BankAccountM:defineAsyncComponent(()=>import('../manage_side/components/BankAccountM.vue')),
        EmployeeCardM:defineAsyncComponent(()=>import('../manage_side/components/EmployeeCardM.vue')),
        GeneralRequestM:defineAsyncComponent(()=>import('../manage_side/components/GeneralRequestM.vue')),
        HakafaM:defineAsyncComponent(()=>import('../manage_side/components/HakafaM.vue')),
        HourlyCorrectionsM:defineAsyncComponent(()=>import('../manage_side/components/HourlyCorrectionsM.vue')),
        IncreasingHoursM:defineAsyncComponent(()=>import('../manage_side/components/IncreasingHoursM.vue')),
        IshurMachalaM:defineAsyncComponent(()=>import('../manage_side/components/IshurMachalaM.vue')),
        ItpatrutM:defineAsyncComponent(()=>import('../manage_side/components/ItpatrutM.vue')),
        MifrahaM:defineAsyncComponent(()=>import('../manage_side/components/MifrahaM.vue')),
        PiturimM:defineAsyncComponent(()=>import('../manage_side/components/PiturimM.vue')),
        PritaM:defineAsyncComponent(()=>import('../manage_side/components/PritaM.vue')),
        ShimuaM:defineAsyncComponent(()=>import('../manage_side/components/ShimuaM.vue')),
        TemporaryWorkerM:defineAsyncComponent(()=>import('../manage_side/components/TemporaryWorkerM.vue')),
        TransferEmployeeM:defineAsyncComponent(()=>import('../manage_side/components/TransferEmployeeM.vue')),
        UpdatePersonalDetailsM:defineAsyncComponent(()=>import('../manage_side/components/UpdatePersonalDetailsM.vue')),
        WorkAccidentM:defineAsyncComponent(()=>import('../manage_side/components/WorkAccidentM.vue')),
        WorkerFormsM:defineAsyncComponent(()=>import('../manage_side/components/WorkerFormsM.vue')),
        UpdateEmailM:defineAsyncComponent(()=>import('../manage_side/components/UpdateEmailM.vue')),
        CycleM:defineAsyncComponent(()=>import('../manage_side/components/CycleM.vue')),
        OfficeEquipmentM:defineAsyncComponent(()=>import('../manage_side/components/OfficeEquipmentM.vue')),
    },
    setup(props){
        const state = ref(null)
        const data = ref(null)
        const go_back=()=>{
            router.go(-1)
        }

        const get_state  = (type)=>{
            switch (type) {
                case 'שינוי פרטי חשבון בנק':
                    state.value = 'BankAccountM'
                    break;
                case 'כרטיס עובד':
                    state.value = 'EmployeeCardM'
                    break;
                case 'בקשה כללית':
                    state.value = 'GeneralRequestM'
                    break;
                case 'הקפה':
                    state.value = 'HakafaM'
                    break;
                case 'תיקוני שעות':
                    state.value = 'HourlyCorrectionsM'
                    break;
                case 'הגדלת שעות':
                    state.value = 'IncreasingHoursM'
                    break;
                case 'אישור מחלה לעובד':
                    state.value = 'IshurMachalaM'
                    break;
                case 'התפטרות':
                    state.value = 'ItpatrutM'
                    break;
                case 'קבלת מפרעה':
                    state.value = 'MifrahaM'
                    break;
                case 'פיטורים':
                    state.value = 'PiturimM'
                    break;
                case 'פריטה':
                    state.value = 'PritaM'
                    break;
                case 'שימוע':
                    state.value = 'ShimuaM'
                    break;
                case 'שעות עבודה לעובד זמני':
                    state.value = 'TemporaryWorkerM'
                    break;
                case 'העברת עובד':
                    state.value = 'TransferEmployeeM'
                    break;
                case 'עדכון פרטים אישיים':
                    state.value = 'UpdatePersonalDetailsM'
                    break;
                case 'תאונת עבודה':
                    state.value = 'WorkAccidentM'
                    break;
                case 'טפסים לעובד':
                    state.value = 'WorkerFormsM'
                    break;
                case 'עדכון כתובת אימייל':
                    state.value = 'UpdateEmailM'
                    break;
                case 'מחזור בקבוקים':
                    state.value = 'CycleM'
                    break;
                case 'ציוד משרדי':
                    state.value = 'OfficeEquipmentM'
                    break;
            }
        }
        
        onBeforeRouteLeave((to,from,next)=>{
            console.log(to);
            if(to.path.includes('office-request-main')){
                localStorage.setItem('office_request_state', 'ManageRequests');
                next()
            }else{
                next()
            }
        })
        
        onMounted(()=>{
            if(props.data){
                data.value = JSON.parse(props.data)
                get_state(data.value.type)
            }
        })
        
        return{state,data,go_back}
    }
}
</script>

<style scoped>
    .manage-request-edit,.no-data{
        width: 100%;
        height: 100%;
    }
    .no-data{
        color:#fff;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>